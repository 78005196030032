import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import Logo from "../Logo/Logo"
import classes from "./Footer.module.scss"
import EmptySpaceSeperator from "../../../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import { injectIntl } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"
import { slugResolver } from "../../../i18n/slugResolver"
import parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook,faLinkedinIn, faInstagram, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons"

const Footer = (props) => {

  const { formatMessage } = props.intl

  const data = useStaticQuery(graphql`
      query {
          services:allWordpressPage(filter: {acf: {page_template: {eq: "services"}}}) {
              edges {
                  node {
                      id
                      slug
                      title
                      template
                      polylang_current_lang
                      path
                  }
              }
          }
      }
  `)


  let servicesMapped = Object.values(data.services.edges).filter((item) => {
    return item.node.polylang_current_lang === props.intl.locale
  })

  return (
    <Container id="footer-outer" className={classes.Footer} fluid>

      <Row>
        <Col sm={12}>

          <Container>
            <Row>
              <Col className="mb-3" sm={4}>
                <EmptySpaceSeperator pixels={40}/>
                <h4>{formatMessage({ id: "officeThessaloniki" })}</h4>
                <div>{formatMessage({ id: "phoneShortHand" })}:
                  <a href="tel:+302310417174"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                    +30 2310 417174
                  </a>
                </div>
                <div><a href="mailto:info@mybusiness360.gr"> Email: info@mybusiness360.gr</a></div>
                <div>{formatMessage({ id: "address" })}: <a href="https://goo.gl/maps/TEBJtfj3sgL2" target="_blank"
                                                            rel="noopener noreferrer"> {formatMessage({ id: "greekAddress" })}</a>
                </div>
                <div>{formatMessage({ id: "workingHours" })}: {formatMessage({ id: "mondayToFriday" })}</div>
                <EmptySpaceSeperator pixels={40}/>
                <h4>{formatMessage({ id: "mainOfficeGeneva" })}</h4>
                <div>{formatMessage({ id: "phoneShortHand" })}:<a href="tel:+41225917992" target="_blank"
                                                                  rel="noopener noreferrer"> +41 (0) 22 591 79 92</a>
                </div>
                <div><a href="mailto:info@mybusiness360.gr">Email: info@mybusiness360.gr</a></div>
                <div>{formatMessage({ id: "address" })}: <a href="https://goo.gl/maps/KP2d2bT5U1a1r7ux8" target="_blank"
                                                            rel="noopener noreferrer"> {formatMessage({ id: "swissAddress" })}</a>
                </div>
                <div>{formatMessage({ id: "workingHours" })}: {formatMessage({ id: "mondayToFriday" })}</div>
              </Col>
              <Col className="text-center" sm={4}>
                <EmptySpaceSeperator pixels={40}/>
                <div className={classes.FooterLogo}>
                  <Logo light={true}/>

                </div>
                <p className={classes.FooterQuoteText}>
                  {formatMessage({ id: "weOnlyHave" })} <strong>{formatMessage({ id: "oneCustomerOne" })}</strong> {formatMessage({ id: "oneCustomerCustomer" })}<br/>
                  <span>{formatMessage({ id: "oneCustomerClient" })}</span>
                </p>
                <p>
                  <a className="socialIcons" href="https://www.facebook.com/mybusiness360/" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a className="socialIcons" href="https://www.linkedin.com/company/mybusiness360" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon  icon={faLinkedinIn} />
                  </a>
                  <a className="socialIcons" href="https://www.instagram.com/mybusiness360/" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon  icon={faInstagram} />
                  </a>
                  <a className="socialIcons" href="https://www.youtube.com/channel/UCDEan2u2AvxpDDWs-lpcQqQ" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon  icon={faYoutubeSquare} />
                  </a>
                </p>
              </Col>
              <Col className="text-right-left-mobile" sm={4}>
                <EmptySpaceSeperator pixels={40}/>
                <h4>{formatMessage({ id: "usefullLinks" })}</h4>
                {
                  servicesMapped.map((item) => {
                    let serviceLink = `${slugResolver(props.intl.locale, item.node.path, true)}`
                    return (
                      <div key={item.node.id}>
                        <Link to={serviceLink}>{parse(item.node.title)}</Link>

                      </div>
                    )
                  })

                }
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="text-center mt-3">{parse(formatMessage({ id: "copyrightText" }))}</p>
              </Col>
            </Row>
          </Container>
        </Col>

      </Row>
    </Container>
  )
}


export default injectIntl(Footer)
