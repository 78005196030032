import React from "react"
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
const NavLink = ({ to, url, ...props }) => {
  let classes = ['nav-link']
  let activeClass = ''
  if(url===to){
    classes.push('active');
    activeClass='active'

  }

  return <Link className={classes.join(" ")} activeClassName={activeClass} {...props}  to={to} />
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default NavLink