import elMessages from "../i18n/el"
import enMessages from "../i18n/en"

const translationHelper = {
  en: enMessages,
  el: elMessages,
}


export default translationHelper
