/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment, useEffect } from "react"

import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "../../components/Layout/Header/Header"
import Footer from "../../components/Layout/Footer/Footer"
import "../../assets/sass/index.scss"
import { IntlProvider, addLocaleData } from "react-intl"
import CookieConsent from "react-cookie-consent"
// Locale data
import enData from "react-intl/locale-data/en"
import elData from "react-intl/locale-data/el"

// Messages
import en from "../../i18n/en.json"
import el from "../../i18n/el.json"

import ReactGA from "react-ga"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import translationHelper from "../../i18n/helper"

const messages = { en, el }
addLocaleData([...enData, ...elData])


const Layout = ({ locale, children, path, ...props }) => {
  library.add(faTimesCircle)

  const initializeCookies = () => {
    console.log("[Enabling Cookies]")

  }

  // useEffect(() => {
  //   ReactGA.initialize(`${process.env.GATSBY_GOOGLE_ANALYTICS_CODE}`)
  //
  // }, [])


  // const loadFacebookChat = () => {
  //   var div = document.createElement("div")
  //   div.className = "fb-customerchat"
  //   div.setAttribute("page_id", "660957563933740")
  //   div.setAttribute("ref", "b64:d2Vic2l0ZQ==")
  //   document.body.appendChild(div)
  //   window.fbMessengerPlugins = window.fbMessengerPlugins || {
  //     init: function() {
  //       FB.init({
  //         appId: "1678638095724206",
  //         autoLogAppEvents: true,
  //         xfbml: true,
  //         version: "v3.3",
  //       })
  //     }, callable: [],
  //   }
  //   window.fbAsyncInit = window.fbAsyncInit || function() {
  //     window.fbMessengerPlugins.callable.forEach(function(item) {
  //       item()
  //     })
  //     window.fbMessengerPlugins.init()
  //   }
  //   setTimeout(function() {
  //     (function(d, s, id) {
  //       var js, fjs = d.getElementsByTagName(s)[0]
  //       if (d.getElementById(id)) {
  //         return
  //       }
  //       js = d.createElement(s)
  //       js.id = id
  //       js.src = "//connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
  //       fjs.parentNode.insertBefore(js, fjs)
  //     }(document, "script", "facebook-jssdk"))
  //   }, 2000)
  //
  // }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Fragment>
        <Header path={path}/>

        <CookieConsent
          enableDeclineButton
          onAccept={initializeCookies}
          // onDecline={() => {alert("nay!")}}
          location="bottom"
          buttonText={translationHelper[locale]["acception"]}
          cookieName="cookieConsentAcception"
          declineButtonText={< FontAwesomeIcon size="1x" title="Close Consent Button" icon={faTimesCircle}/>}
          declineButtonClasses="consentDeclineBtn"
          buttonClasses="consentAcceptBtn"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
          flipButtons={true}
        >
          {translationHelper[locale]["cookieConsentText"]}

        </CookieConsent>
        <main>{children}</main>
        <Footer/>
      </Fragment>
    </IntlProvider>

  )
}


Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Layout
