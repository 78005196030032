export const findPathFromFullUrl = (url) => {

  // Because Wordpress is pure shit, some urls could be in different protocols
  // So its better to run the replace for both http and https to ensure that everything would be ok
  // return url.replace(`${process.env.GATSBY_HEADLESS_PROTOCOL}://${process.env.GATSBY_HEADLESS_URL}`,'');
  let replacedUrl = url.replace(`http://${process.env.GATSBY_HEADLESS_URL}`, "")
  replacedUrl = replacedUrl.replace(`https://${process.env.GATSBY_HEADLESS_URL}`, "")
  return replacedUrl

}


// left is the loop key - right is the col-md-number for the masonry
// All Masonry packets sucked d1ck so why not use this shit instead ?! xD
// I re-loop the pattern if the items are more than the array length

export const masonryPatternOne = [
  6,
  3,
  3,
  3,
  3,
  6,
  6,
  6,
]