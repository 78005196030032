import React from "react"
import PropTypes from "prop-types"

const emptySpaceSeperator = (props) => {
  const style = {
    marginTop: !props.noTop ? props.pixels : 0 + "px",
    marginBottom: !props.noBottom ? props.pixels : 0 + "px",
  }
  return (
    <div style={style}/>

  )
}

emptySpaceSeperator.defaultProps = {
  pixels: 20,
  noTop: false,
  noBottom: false,
}
emptySpaceSeperator.propTypes = {
  pixels: PropTypes.number,
  noTop: PropTypes.bool,
  noBottom: PropTypes.bool,
}
export default emptySpaceSeperator
