import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { findPathFromFullUrl } from "../../../../utility/helpers"
import localesConfig from "../../../../constants/locales"
import Nav from "react-bootstrap/Nav"

import NavDropdown from "react-bootstrap/NavDropdown"
import NavLink from "../../../../components/NavLink"
import parse from "html-react-parser"
import {slugResolver} from "../../../../i18n/slugResolver"

export default ({ path, locale }) => {
  console.debug(path)
  const mainMenuId = localesConfig[locale].menuId
  const urlResolver = (lang) => {

    return localesConfig[lang].default ? "" : localesConfig[lang].urlPrefix
  }
  const data = useStaticQuery(graphql`
      query{
          allWordpressWpApiMenusMenusItems {
              edges {
                  node {
                      wordpress_id
                      slug
                      name
                      count
                      description
                      id
                      items {
                          attr
                          classes
                          description
                          object
                          object_id
                          object_slug
                          order
                          target
                          title
                          type
                          type_label
                          url
                          wordpress_children {
                              attr
                              description
                              classes
                              object_slug
                              wordpress_parent
                              xfn
                              wordpress_id
                              url
                              type_label
                              type
                              title
                              target
                              order
                              object_id
                              object
                          }
                          xfn
                          wordpress_parent
                          wordpress_id
                      }
                  }
              }
          }
      }
  `)


  const menusFiltered = data.allWordpressWpApiMenusMenusItems.edges.find(edge => edge.node.wordpress_id === mainMenuId)

  const prefix = urlResolver(locale)

  const prefixableTypes = [
    "custom",
  ]
  const shouldAddPrefix = (url, prefix, type) => {
    return prefixableTypes.includes(type) ? `${prefix}${url}` : url
  }
  return (
    <Fragment>
      {
        menusFiltered.node.items.map(item => {
          if (item.wordpress_children) {
            let navClass = null
            let childrenClass = null
            let navChildren = item.wordpress_children.map(submenu => {
              childrenClass = null
              if (path === findPathFromFullUrl(submenu.url)) {
                navClass = "active"
                childrenClass = "active"
              }

              let menuUrl = slugResolver(locale,submenu.url,true)
              return <NavDropdown.Item className={childrenClass} key={submenu.wordpress_id} url={path} as={NavLink}
                                       to={shouldAddPrefix(findPathFromFullUrl(menuUrl),prefix,submenu.object)}>{parse(submenu.title)}</NavDropdown.Item>

            })
            return (<NavDropdown className={navClass} key={item.wordpress_id} title={item.title} url={path} as={NavLink}
                                 to={shouldAddPrefix(findPathFromFullUrl(slugResolver(locale,item.url,false)),prefix,item.object)}
                                 id={item.object.slug}>
              {navChildren}
            </NavDropdown>)
          } else {
            return <Nav.Link
              key={item.wordpress_id}
              to={shouldAddPrefix(findPathFromFullUrl(item.url),prefix,item.object)}
              as={NavLink}
              url={path}>{parse(item.title)}</Nav.Link>

          }
        })
      }
    </Fragment>

  )
}