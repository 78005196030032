export const slugResolver = (lang, slug, middle = false) => {
  if (process.env.GATSBY_HEADLESS_REMOVE_LANG_PREFIX_FROM_PATHS) {
    let pattern = "-" + lang
    let patternSlash = pattern + "/"

    // If we cant to Capture the Categories that are inside the URL

    if (middle !== false) {
      pattern = pattern + "/"

      if (slug.includes(pattern)) {
        return slug.replace(new RegExp(pattern), "/")
      }
    }

    // If it ends with slug but has a trailing slash too
    if (slug.endsWith(patternSlash)) {
      patternSlash = patternSlash + "$"
      return slug.replace(new RegExp(patternSlash), "")

    } else if (slug.endsWith(pattern)) {
      pattern = pattern + "$"
      return slug.replace(new RegExp(pattern), "")
    }

  }
  return slug
}