import React from "react"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Logo = (props) => {
  const data = useStaticQuery(query)
  return (
    <Img loading="eager" imgStyle={props.containerStyle} alt="mybusiness360 Κατασκευή Ιστοσελίδων & Eshop Logo"  fixed={props.light ? data.lightLogo.childImageSharp.fixed :data.logo.childImageSharp.fixed}/>

  )

}
export const query = graphql`
    query {
        logo:file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width:210) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64

                }
            }
        }
        lightLogo: file(relativePath: { eq: "mybusiness360-logo-light.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width:210) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64

                }
            }
        }
    }
`

export default Logo
