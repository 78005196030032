module.exports = {
  "el": {
    path: "el",
    urlPrefix: "el",
    locale: "Greek",
    default: true,
    menuId: 19,
    flag: "flags/el.png"

  },
  "en": {
    path: "en",
    urlPrefix: "en",
    locale: "English",
    menuId:64,
    flag: "flags/en.png"


  },
}